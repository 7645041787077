
import { defineComponent,reactive} from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@/utils/axios";
import Swiper from "./modules/swiper.vue";
import SubNav from "./modules/subNav.vue";
import Box from "./modules/box.vue";
import Sixbox from "./modules/SixBoxa.vue";
import FourBoxa from "./modules/FourBoxa.vue";
import Message from "@/components/message/Message.vue";
import { ref } from "vue";
import * as echarts from "echarts";
import "../../../node_modules/echarts/map/china.js"; // 引入中国地图数据
export default defineComponent({
  name: "home",
  components: {
    Swiper,
    SubNav,
    Message,
    Box,
    Sixbox,
    FourBoxa,
  },
  data() {
    return {
      ambassadorImg: require("@/assets/1new/赋能工程/赋能大使.jpg"),
      list: [
        {
          img: require("@/assets/1new/赋能工程/4.jpg"),
          name: "专家一",
          introduce:
            "专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介专家简介简介",
        },
      ],
    };
  },
  methods: {
    back(){
this.$router.go(-1)
    },
  },
  setup(){
     var mess = reactive({ data: "" });
    const route = useRoute();
    axios
      .get(
        "https://www.xsy985.com/api/expert/expert/" + route.params.id,
      )
      .then(function (response) {
        console.log('11111',response.data);
        mess.data = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    return {
      mess,
    };
  }
});
